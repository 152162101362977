import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  GroupLeavesType,
  LeaveAppType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../contexts/user';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeave } from '../../../../hooks/hrd/leave/use-leave';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import {
  employeeIconMapperByGrade,
  employeeLeaveStatusMapper,
  employeeLeaveTypeIconMapper,
} from '../../../../utils/common';
import { UserRendererInterface } from '../../../common/fragments/user';
import { EditLeave } from './forms/edit-bulk-leave';
import { renderRequestLeaveFormV2 } from './forms/request-leave/modal-content-v2';

type RenderEmployeesMonthLeavesBook = {
  useLeaves: UseLeaves;
  useFileTransfer: UseFileTransfer;
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeave: UseLeave;
  useUserAuthorization: UseUserAuthorization;
  leaveService: LeaveInterface;
  userRendererService: UserRendererInterface;
};

export const renderEmployeeMonthLeavesBook = ({
  useLeaves,
  useFileTransfer,
  useEmployeeProfiles,
  useLeave,
  useUserAuthorization,
  leaveService,
  userRendererService,
}: RenderEmployeesMonthLeavesBook) => {
  const FileViewerUploader = renderFileViewerUploader();

  return function EmployeeLeavesBook() {
    const [leaves, setLeaves] = useState<LeaveAppType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { syncSet: setLeave } = useLeave();
    const { user: currentUser } = useContext(UserContext);
    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();
    const { get: getAllowedActions } = useUserAuthorization();

    const [date, setDate] = useState<{
      mode: 'date' | 'month';
      value: string;
    }>(
      JSON.parse(
        localStorage.getItem('leave-month-book') ||
          JSON.stringify({
            mode: 'month',
            value: moment.utc().format('YYYY-MM'),
          })
      )
    );

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const getAllLeaves = () => {
      // getLeaves({ date: date.value, crossGroup: 'true' });
      setLoading(true);
      leaveService.getMonthLeaves(date.value).then((res) => {
        setLeaves(res);
        setLoading(false);
      });
    };

    useEffect(() => {
      getAllLeaves();
      localStorage.setItem('leave-month-book', JSON.stringify(date));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    // const RequestLeave = renderRequestLeave({
    //   useEmployeeProfiles,
    //   useLeaves,
    //   leaveService,
    //   currentUser,
    //   setModal,
    //   modal,
    //   buttonStyle:
    //     'bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600 truncate mx-0.5',
    // });

    const cancelEmployeeLeave = async (ids: string[]) => {
      const type = leaves?.find((x) => x.id === ids[0])?.type;
      if (type) {
        const response = await leaveService.cancelLeaves({
          ids: ids,
          type: type,
        });

        console.log(response);
      }

      toast('Employee leaves cancelled');
      getAllLeaves();
    };

    const Card = renderCardComponent();
    const CardHeader: CardHeader = {
      title: 'Employees Month Leaves-Book',
      icon: (
        <BookOpenIcon className='w-8 h-8 stroke-indigo-900 inline self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          <span className='capitalize text-black'>{date.mode}: </span>{' '}
          {moment(
            date.value,
            date.mode === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'
          ).format(date.mode === 'date' ? 'ddd MMM DD YYYY' : 'MMM, YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select {date.mode}</span>
              <input
                className='inline border-2 rounded'
                type={date.mode}
                value={date.value}
                onChange={(e) =>
                  setDate({
                    mode: date.mode,
                    value: e.target.value,
                  })
                }
              />
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'Request Leave',
            modal: {
              title: 'Request Leave',
              content: ({ onClose }) => {
                const RequestLeaveV2 = renderRequestLeaveFormV2({
                  leaveService,
                  currentUser: currentUser as any,
                  useEmployeeProfiles,
                  useLeaves,
                });

                return <RequestLeaveV2 data={{}} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const TableHeader: TableHeader = [
      [
        { name: 'Particulars' },
        {
          name: 'Applied On',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Reason',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Activity',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Status',
          style: 'hidden md:table-cell',
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Approve',
          auth: 'UI:BTN-APRV-LEAVE:VIEW',
          show: ({ leave }: { leave: GroupLeavesType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(leave.leave.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ leave }) => {
            const ids = leave.leaves.map((x) => x.id);
            const type = leave.leave.type;
            return {
              title: 'Are you sure you want to approve Leave?',
              message: `${type.split('-').join(' ')} for ${
                leave.leave.employee.name
              } will be approved.`,
              type: 'warning',
              onConfirm: async () => {
                await leaveService.approveLeaves({
                  ids: ids,
                  type,
                });
                toast('Leaves approved successfully');
                await sleep(2000);
                getAllLeaves();
              },
            };
          },
        },
        {
          name: 'Edit',
          show: ({ leave }: { leave: GroupLeavesType }) => {
            if (['pending', 'approved'].includes(leave.leave.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit Leave',
            content: ({
              data: { leave },
              onClose,
            }: {
              data: { leave: GroupLeavesType };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <EditLeave leaves={leaves} leave={leave} />
                </div>
              );
            },
          },
        },
        {
          name: 'Cancel',
          show: ({ leave }: { leave: GroupLeavesType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['pending', 'approved'].includes(leave.leave.status) &&
              leave.leaves.length <= 2
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ leave }) => {
            const ids: Array<string> = leave.leaves.map((x) => x.id);
            return {
              title: 'Are you sure you want to cancel Leave?',
              message: `Are you sure, cancelling leave for ${leave?.leave?.employee.name}`,
              type: 'warning',
              onConfirm: async () => {
                await cancelEmployeeLeave(ids);
                toast('Leaves cancelled successfully');
                await sleep(2000);
                getAllLeaves();
              },
            };
          },
        },
        {
          name: 'Upload Document',
          show: ({ leave }: { leave: GroupLeavesType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.

            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Upload Document',
            content: ({
              data: { leave },
              onClose,
            }: {
              data: {
                leave: GroupLeavesType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <FileViewerUploader
                    mode='upload'
                    url={leave.leave.details?.document || ''}
                    useFileTransfer={useFileTransfer}
                    path={`${leave.leave?.companyGroup?.id}/${leave.leave?.company?.id}/employees/leaves/${leave.leave?.employee?.id}/${leave.leave?.type}-${leave.leave?.id}.pdf`.replaceAll(
                      ' ',
                      '-'
                    )}
                    type='private'
                    onUpload={async (path) => {
                      let leavedoc = leave.leave.details?.document || {};
                      if (leavedoc) {
                        leavedoc = path.url;
                        await setLeave({
                          id: leave.leave.id,
                          details: {
                            document: leavedoc,
                          },
                        } as LeaveAppType);
                      }
                    }}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const mergedList: Array<{
      leave: LeaveAppType;
      count: number;
      leaves: Array<LeaveAppType>;
    }> = [];
    for (let i = 0; leaves && i < leaves?.length; i++) {
      const curr = leaves[i];
      const matched = mergedList.find(
        (x) =>
          x.leave.dateStart === curr.dateStart &&
          x.leave.dateEnd === curr.dateEnd &&
          x.leave.employee.id === curr.employee.id &&
          x.leave.type === curr.type &&
          x.leave.status === curr.status
      );
      if (matched) {
        matched.leaves.push(curr);
        matched.count += curr.duration === 'full-day' ? 2 : 1;
      } else {
        const newObj = {
          leave: curr,
          count: curr.duration === 'full-day' ? 2 : 1,
          leaves: [curr],
        };
        mergedList.push(newObj);
      }
    }

    const leavesList = mergedList?.filter(
      (l) => l.leave.status !== 'un-consumed'
    );

    function renderTableBodyMapper(
      list: {
        leave: LeaveAppType;
        count: number;
        leaves: Array<LeaveAppType>;
      }[]
    ): TableBody {
      return (
        list?.map((e) => ({
          rowData: {
            leave: e,
          },
          cells: [
            {
              value: (
                <div className='flex'>
                  <div className='my-auto mr-2'>
                    <span className='text-3xl'>
                      {employeeLeaveTypeIconMapper({ type: e.leave.type })}
                    </span>
                  </div>
                  <div>
                    <div className='text-lg'>
                      {employeeIconMapperByGrade({
                        grade: (e.leave.employee as any)?.details?.grade,
                      })}
                      <a
                        target='_blank'
                        href={`/users/profile/${e.leave.employee.id}`}
                        rel='noreferrer'
                      >
                        {e.leave.employee?.name}
                      </a>
                    </div>
                    <div className='italic'>
                      <span className='text-blue-600'>{e?.leave.type}</span>{' '}
                      from{' '}
                      {moment(e?.leave.dateStart, 'YYYY-MM-DD').format('ll')} to{' '}
                      {moment(e.leave.dateEnd, 'YYYY-MM-DD').format('ll')}{' '}
                      {<b>{e.count / 2} Day(s)</b>}
                      <span className='mx-0.5'>
                        {employeeLeaveStatusMapper(e.leave.status)}{' '}
                        {e?.leave.status}
                      </span>
                    </div>
                    <div className='md:hidden'>
                      <div>
                        applied on {(e.leave.details as any)?.appliedDate || ''}{' '}
                        by {e.leave.appliedBy.name}
                      </div>

                      {['cancelled', 'approved', 'redeemed'].includes(
                        e.leave.status
                      ) ? (
                        <div>
                          {e.leave.status} by{' '}
                          {['redeemed', 'approved'].includes(e.leave.status)
                            ? e.leave.approvedBy?.name
                            : e.leave.lastModifiedBy.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: (e.leave.details as any)?.appliedDate || '',
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <div>
                  <div className='italic'>{e?.leave.reason}</div>
                  {e?.leave.details?.document ? (
                    <>
                      <span>Proofs:</span>
                      <FileViewerUploader
                        mode='upload'
                        url={e?.leave.details?.document || ''}
                        useFileTransfer={useFileTransfer}
                        path={`${e?.leave.companyGroup?.id}/${e?.leave.company?.id}/employees/leaves/${e?.leave.employee?.id}/${e?.leave.type}-${e?.leave.id}.pdf`.replaceAll(
                          ' ',
                          '-'
                        )}
                        type='private'
                        onUpload={async (path) => {
                          let leavedoc = e.leave.details?.document || {};
                          if (leavedoc) {
                            leavedoc = path.url;
                            await setLeave({
                              id: e.leave.id,
                              details: {
                                document: leavedoc,
                              },
                            } as LeaveAppType);
                          }
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <div>
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={e.leave.appliedBy.id}
                    name={e.leave.appliedBy.name}
                    extraInfo={'applied By'}
                  />
                  {e.leave.status === 'redeemed' ||
                  e.leave.status === 'approved' ||
                  (e.leave.status === 'cancelled' && e.leave.approvedBy) ? (
                    <userRendererService.userCard
                      link={true}
                      size='small'
                      id={
                        e.leave.status === 'cancelled'
                          ? e.leave.lastModifiedBy.id
                          : e.leave.approvedBy?.id || ''
                      }
                      name={
                        e.leave.status === 'cancelled'
                          ? e.leave.lastModifiedBy.name
                          : e.leave.approvedBy?.name || '??'
                      }
                      extraInfo={`${e.leave.status} By`}
                    />
                  ) : null}
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <div>
                  {employeeLeaveStatusMapper(e.leave.status)} {e?.leave.status}
                </div>
              ),
            },
          ],
        })) || []
      );
    }

    const TableBody: TableBody = renderTableBodyMapper(leavesList);

    const FilteredTableBody: TableBody = renderTableBodyMapper(
      _.sortBy(
        leavesList?.filter((x) => {
          const emp = employees?.find((e) => e.id === x.leave.employee.id);
          if (
            emp &&
            (emp.details?.authorizations?.attendanceAuthorizer?.id ||
              emp.details?.authorizations?.attendanceSupervisor?.id) ===
              currentUser.id
          ) {
            return true;
          }
          return false;
        }),
        (l) => {
          switch (l.leave.status) {
            case 'pending':
              return 0;
            case 'approved':
              return 1;
            case 'redeemed':
              return 2;
            default:
              return 3;
          }
        }
      )
    );

    const CardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                <div className='my-5'>
                  <Card
                    header={{ title: ' Requests for you' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={FilteredTableBody}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>

                <div className='my-5'>
                  <div className='text-center p-1 border border-gray-200 font-bold'>
                    All Requests
                  </div>
                  <Table
                    header={TableHeader}
                    body={TableBody}
                    actions={addActions()}
                    auth={{ actions: getAllowedActions().actions }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={CardHeader} body={CardBody} />
      </>
    );
  };
};
