/* eslint-disable no-unused-vars */
import { OnboardEmployeeType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { LocationContext } from '../../../../contexts/location';
import { UserContext } from '../../../../contexts/user';
import { UseCompanies } from '../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseLocations } from '../../../../hooks/admin/location-admin/use-locations';
import { UseOnboardEmployee } from '../../../../hooks/admin/onboard-employee/use-onboard-employee';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { renderAddEmployeeOnboardForm } from '../form/add-employee-onboard';

export type renderEmployeeOnboardProps = {
  useEmployeeProfiles: UseEmployeeProfiles;
  useCompanies: UseCompanies;
  useDepartments: UseDepartments;
  useLocations: UseLocations;
  useOnboardEmployee: UseOnboardEmployee;
  useEmployeeCategories: UseEmployeeCategories;
};

export function renderEmployeeOnboards({
  useEmployeeProfiles,
  useCompanies,
  useDepartments,
  useLocations,
  useOnboardEmployee,
  useEmployeeCategories,
}: renderEmployeeOnboardProps): () => JSX.Element {
  return function EmployeeOnboards(): JSX.Element {
    // eslint-disable-next-line
    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();
    const { data: companies, getAll: getAllCompanies } = useCompanies();
    const { data: locations, getAll: getLocations } = useLocations();
    const { data: departments, getAll: getDepartments } = useDepartments();

    const { syncSet: setOnboardEmployee } = useOnboardEmployee();

    const { user: currentUser } = useContext(UserContext);
    const { company: currentCompany, companyGroup } = useContext(
      LocationContext
    );

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      getAllCompanies();
      getDepartments();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentCompany.id) {
        getLocations({
          getAll: true,
          companyId: currentCompany.id,
          type: 'real',
        });
      }
      // eslint-disable-next-line
    }, [currentCompany]);

    const emprcds = _.orderBy(
      employees,
      (e) => parseInt(e.details?.employeeId || '0'),
      ['desc']
    );

    const rcds = emprcds?.filter(
      (x) => x.details.workTimeLines?.find((y) => y.activity === 'joined')?.date
    );

    const OnboardEmployeeRcds = _.orderBy(
      rcds,
      (e) => [
        e.details.employeeId,
        moment(
          e.details.workTimeLines?.find((y) => y.activity === 'joined')?.date
        ).format('YYYYMMDD'),
      ],
      ['desc', 'desc']
    );

    const saveOnboardEmployee = async (form: Partial<any>) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as OnboardEmployeeType;
        console.log('finalData', finalData);
        await setOnboardEmployee(finalData as OnboardEmployeeType);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const OnboardEmployeeTable = renderTableComponent();
    const OnboardEmployeeTableHeader: TableHeader = [
      [
        { name: 'Employee ID' },
        { name: 'Employee Name' },
        { name: 'Company' },
        { name: 'Date' },
      ],
    ];

    const tableData =
      OnboardEmployeeRcds?.map((r, idx) => ({
        rowData: {
          onboardEmployee: r,
        },
        cells: [
          { value: r.details?.employeeId },
          { value: r.user.name },
          { value: companies?.find((x) => x.id === r.company.id)?.name || '' },
          {
            value:
              moment(
                r.details.workTimeLines?.find((x) => x.activity === 'joined')
                  ?.date
              ).format('DD-MMM-YYYY') || '',
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Onboard Employees',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Onboard Employee',
            behaviour: 'modal',
            modal: {
              title: 'Add Onboard Employee',
              size: 'large',
              content: ({ onClose }) => {
                const Form = renderAddEmployeeOnboardForm({
                  newEmpId: `${
                    parseInt(
                      `${OnboardEmployeeRcds[0]?.details?.employeeId}` || '0'
                    ) + 1
                  }`.toString(),
                  locations: locations || [],
                  useEmployeeCategories,
                  useEmployeeProfiles,
                  useCompanies,
                });
                return (
                  <Form
                    departments={departments || []}
                    currentCompany={currentCompany}
                    onSave={async (form) => {
                      saveOnboardEmployee(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <OnboardEmployeeTable
          header={OnboardEmployeeTableHeader}
          body={tableBody}
        />
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
