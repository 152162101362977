import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';

export function renderTallyDaybook() {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function Daybook() {
    const [range] = useState<{
      start: string;
      end: string;
    }>({
      start: moment().subtract(3, 'M').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    });
    return (
      <div>
        <Card
          header={{
            title: 'Daybook',
            actions: [
              {
                type: 'button',
                button: {
                  name: `${range.start} to ${range.end}`,
                  behaviour: 'modal',
                  modal: {
                    title: 'Change range',
                    content: ({ onClose }) => {
                      return <div>Change Range</div>;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={[
                    [
                      { name: 'Date', rowSpan: 2 },
                      { name: 'Particulars', rowSpan: 2 },
                      { name: 'Voucher Type', rowSpan: 2 },
                      { name: 'Debit Amount' },
                      { name: 'Credit Amount' },
                    ],
                    [
                      {
                        name: 'Inwards Qty',
                        style: 'border border-left-gray-100',
                      },
                      { name: 'Outwards Qty' },
                    ],
                  ]}
                  body={[]}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
