import { renderCardComponent, renderFormV2 } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { useEffect, useState } from 'react';
import { UseAccountGroup } from '../../../../hooks/accounts/tally/account-group/use-account-group';
import { UseAccountGroups } from '../../../../hooks/accounts/tally/account-group/use-account-groups';
import { AccountGroup } from '../../../../models/types/accounts/account-group';
import { TreeIcon } from '../../../../utils/common';

type TreeNodeType = {
  type: string;
  key: string;
  isLeaf: boolean;
  children: Array<TreeNodeType>;
  title: string;
};

type RenderGodownTree = {
  useAccountGroups: UseAccountGroups;
  useAccountGroup: UseAccountGroup;
};

export function renderGroupTree({
  useAccountGroups,
  useAccountGroup,
}: RenderGodownTree): () => JSX.Element {
  const Card = renderCardComponent();

  return function AccountGroupTree() {
    const { data: groups, getAll: getGroups } = useAccountGroups();
    const { syncSet: setAccountGroup } = useAccountGroup();

    const [nodeData, setNodeData] = useState<TreeNodeType>();

    useEffect(() => {
      getGroups();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (groups?.length) {
        setNodeData(renderTreeNode(_.sortBy(groups, 'name')));
      }
    }, [groups]);

    const onRightClick = (event, node) => {
      // if (node.key && node.key !== 'root') {
      //   _.get(node.key);
      // }
    };

    return (
      <Card
        header={{
          title: 'Groups',
          actions: [
            {
              type: 'button',
              button: {
                name: 'Add',
                behaviour: 'modal',
                modal: {
                  title: 'Add Group',
                  content: ({ onClose }) => {
                    const Form = renderFormV2({
                      initialFormState: {
                        name: '',
                        description: '',
                        natureOfGroup: '',
                        ownership: '',
                        parentGroup: { id: '', name: '' },
                      },
                      fieldsData: [
                        { type: 'input', property: 'name', required: true },
                        { type: 'input', property: 'description' },
                        {
                          type: 'select',
                          property: 'natureOfGroup',
                          required: true,
                          options: [
                            { text: 'Select', value: '' },
                            { text: 'liabilities', value: 'liabilities' },
                            { text: 'assets', value: 'assets' },
                            { text: 'income', value: 'income' },
                            { text: 'expenses', value: 'expenses' },
                          ],
                        },
                        {
                          type: 'select',
                          property: 'ownership',
                          required: true,
                          options: [
                            { text: 'Select', value: '' },
                            { text: 'system', value: 'system' },
                            { text: 'user', value: 'user' },
                          ],
                        },
                        {
                          type: 'searchable-select',
                          property: 'parentGroup',
                          searchOptions: {
                            useSearch: useAccountGroups,
                            onSearchValueSelect(u) {},
                          },
                        },
                      ],
                      style: 'w-1/3',
                      onSubmit: async (form) => {
                        console.log(form);
                        if (!(form.parentGroup as any)?.id) {
                          form.parentGroup = null;
                        }
                        await setAccountGroup(form as any);
                        onClose();
                      },
                      mapTToU: (t) => t,
                    });

                    return <Form />;
                  },
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div className='flex min-h-96'>
              {nodeData ? (
                <Tree
                  onClick={onRightClick}
                  onCheck={onRightClick}
                  defaultExpandAll
                  className='max-h-[600px] p-2 text-lg scrollbar-none overflow-y-auto w-3/5'
                  treeData={[renderTree(nodeData)]}
                />
              ) : null}
            </div>
          ),
        }}
      />
    );
  };
}

function renderTreeNode(groups: Array<AccountGroup>) {
  const data: TreeNodeType = {
    type: 'root',
    key: 'root',
    isLeaf: false,
    children: [],
    title: 'Groups',
  };

  const recursiveFun = (
    groups: AccountGroup[],
    parentId: string
  ): TreeNodeType[] => {
    console.log(groups);
    return groups
      .filter(
        (g) =>
          g.parentGroup?.id === parentId ||
          (parentId === 'root' && !g.parentGroup)
      )
      .map((g) => {
        return {
          type: 'group',
          key: g.id,
          isLeaf: false,
          children: recursiveFun(groups, g.id),
          title: g.name,
        };
      });
  };
  data.children = recursiveFun(groups, 'root');
  return data;
}

function renderTree(data: TreeNodeType) {
  const result = {
    key: data.key,
    children: data.children.map((d) => renderTree(d)),
    title: data.title,
    icon: (
      <div className='w-5 text-indigo-900 self-center'>
        <TreeIcon type={data.type} />
      </div>
    ),
    expandAction: 'click',
  };
  return result;
}
