import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../../contexts/location';
import { SalaryInterface } from '../../../../models/interfaces/hrd/salary';
import { findParamType, formatParam } from '../utils/generate-salary';

export function renderSimpleSalaryView({
  employee,
  salaryRevision,
  availableSalaryRevisions,
  selectedParam,
  salaryService,
  persist,
  mode,
}: {
  employee: EmployeeProfileType;
  salaryRevision: SalaryRevisionType;
  availableSalaryRevisions: SalaryRevisionType[];
  selectedParam: SalaryParamType;
  salaryService: SalaryInterface;
  persist: boolean;
  mode: 'real' | 'simulate' | 'recalculate';
}): (props: { date: string }) => JSX.Element {
  return function SalaryView({ date }: { date: string }): JSX.Element {
    const [finalSalaryParams, setFinalSalaryParams] = useState<{
      other: any;
      earning: any;
      deduction: any;
    }>({ other: {}, earning: {}, deduction: {} });
    const [currTab, setCurrTab] = useState<string>('earning');
    const [loading, setLoading] = useState<boolean>(false);
    const [duration, setDuration] = useState<'Monthly' | 'Annual'>('Monthly');
    const { cgSetting } = useContext(LocationContext);

    const [selectedSalRev, setSelectedSalRev] = useState<SalaryRevisionType>(
      salaryRevision
    );

    useEffect(() => {
      setLoading(true);
      if (employee.id && date && selectedSalRev?.id && selectedParam?.id) {
        salaryService
          .calculateSalary({
            employeeId: employee.id,
            month: date,
            salaryRevisionId: selectedSalRev.id,
            salaryParamId: selectedParam.id,
            mode: mode,
            persist,
            companyGroupSetting: cgSetting,
            duration,
          })
          .then((res) => {
            if (res.salary?.salaryParams) {
              setFinalSalaryParams(res.salary?.salaryParams);
            }

            setLoading(false);
          });
      } else {
        setLoading(false);
      }
      // eslint-disable-next-line
    }, [selectedSalRev, duration]);

    return (
      <div>
        <div className='flex'>
          <div className='border border-gray-200 mx-2'>
            Duration:{' '}
            {mode === 'simulate' ? (
              <select
                value={duration}
                onChange={(evt) => {
                  setDuration(evt.target.value as any);
                }}
              >
                <option value='Monthly'>Monthly</option>
                <option value='Annual'>Annual</option>
              </select>
            ) : (
              <>{duration}</>
            )}
          </div>
          <div className='border border-gray-200 mx-2'>
            Salary Revision:
            {!persist && availableSalaryRevisions.length ? (
              <select
                value={selectedSalRev.id}
                onChange={(evt) => {
                  const newSelected = availableSalaryRevisions.find(
                    (x) => x.id === evt.target.value
                  );

                  if (newSelected) {
                    setSelectedSalRev(newSelected);
                  }
                }}
              >
                {availableSalaryRevisions.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
        </div>

        {!selectedSalRev ? (
          <div>No Salary revision for {employee.name}</div>
        ) : (
          <>
            {loading ? (
              <div className='flex justify-center'>
                <Recat className='h-3 z-50' />
              </div>
            ) : (
              <>
                <div className='w-full'>
                  <div className='flex w-full'>
                    <div
                      onClick={() => setCurrTab('earning')}
                      className={`text-center border border-gray-200 p-1 basis-1/3 ${
                        currTab === 'earning'
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } cursor-pointer`}
                    >
                      Earning
                    </div>
                    <div
                      onClick={() => setCurrTab('deduction')}
                      className={`text-center border border-gray-200 p-1 basis-1/3 ${
                        currTab === 'deduction'
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } cursor-pointer`}
                    >
                      Deduction
                    </div>
                    <div
                      onClick={() => setCurrTab('other')}
                      className={`text-center border border-gray-200 p-1 basis-1/3 ${
                        currTab === 'other'
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } cursor-pointer`}
                    >
                      Hidden
                    </div>
                  </div>

                  <div className='flex justify-center'>
                    <div className='bg-slate-50 w-1/2'>
                      <table>
                        <thead>
                          <tr>
                            <th>Particulars</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.keys(finalSalaryParams[currTab]).map((key) => {
                            const value = formatParam({
                              value: finalSalaryParams[currTab][key],
                              metric: findParamType(
                                key,
                                selectedParam.details.inner,
                                'metric'
                              ),
                              scale: findParamType(
                                key,
                                selectedParam.details.inner,
                                'scale'
                              ),
                            });
                            return (
                              <tr key={key}>
                                <td>{key}</td>
                                <td className='text-right'>
                                  {['string', 'number', 'boolean'].includes(
                                    typeof value
                                  )
                                    ? value
                                    : 'invalid-data'}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };
}
