import { LocationProvider } from '../datalayer/providers/location';
import { UserProvider } from '../datalayer/providers/user';
import { Config, Hooks } from '../models/interfaces/common/app-config';
import { UserRenderer } from '../modules/common/fragments/user';
import {
  accountGroupService,
  daybookService,
  ledgerService,
  useAccountGroup,
  useAccountGroups,
  useDaybook,
  useDaybooks,
  useLedger,
  useLedgers,
  usePaginatedDaybook,
  useVoucher,
  useVouchers,
  useVoucherTransaction,
  useVoucherTransactions,
} from './accounts';
import {
  authorizationService,
  companyGroupAdminService,
  companyGroupSettingService,
  currencyService,
  deliveryTermsService,
  gstService,
  locationsService,
  loginService,
  metricsService,
  notificationActionService,
  paymentTermsService,
  resetPasswordService,
  roleAdminService,
  setPasswordService,
  useActionAdmin,
  useActions,
  useAppearance,
  useAppearances,
  useAuthorization,
  useAuthorizations,
  useColor,
  useColors,
  useCompanies,
  useCompanyAdmin,
  useCompanyGroupAdmin,
  useCompanyGroupSetting,
  useCompanyGroupSettings,
  useCurrencies,
  useCurrency,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useCurrentLocation,
  useCurrentUser,
  useCurrentUserRoles,
  useDeliveryTerm,
  useDeliveryTerms,
  useDepartment,
  useDepartments,
  useEmployeeCategories,
  useEmployeeCategory,
  useEmployeeDesignation,
  useEmployeeDesignations,
  useGetAllRoles,
  useGst,
  useGsts,
  useHsn,
  useHsns,
  useLocationAdmin,
  useLocations,
  useLogin,
  useLogout,
  useMetric,
  useMetrics,
  useModuleAdmin,
  useModules,
  useNotificationAction,
  useNotificationActions,
  useOdour,
  useOdours,
  useOnboardEmployee,
  useOnboardEmployees,
  usePaginatedAppearances,
  usePaginatedAuthorizations,
  usePaginatedColors,
  usePaginatedCurrencies,
  usePaginatedDeliveryTerms,
  usePaginatedEmployeeCategories,
  usePaginatedEmployeeDesignations,
  usePaginatedGST,
  usePaginatedHsns,
  usePaginatedMetrics,
  usePaginatedOdours,
  usePaginatedPaymentTerms,
  usePaginatedResourcesAdmin,
  usePaginatedRoles,
  usePaginatedUsers,
  usePaymentTerm,
  usePaymentTerms,
  userAdminService,
  useResetPassword,
  useResourceAdmin,
  useResourcesAdmin,
  useRoleAdmin,
  useSetPassword,
  useSisterLocation,
  useUserAdmin,
  useUserAuthorization,
  useUserRoles,
  useUsers,
} from './admin';
import {
  dashboardService,
  useBomEstimate,
  useDashboard,
  useDashboards,
  useHrdDashboardReport,
} from './analytics';
import { useTemplate, useTemplates } from './communication';
import {
  useMisconduct,
  useMisconductRuleBook,
  useMisconductRuleBooks,
  useMisconducts,
  useRuleRequest,
  useRuleRequests,
} from './disciplinary';
import {
  fileTransferService,
  useFileTransfer,
  usePublicTransfer,
} from './file-transfer';
import {
  attendanceDeviceService,
  attendanceService,
  employeeExitService,
  employeeLettersService,
  employeeProfileService,
  esicReturnService,
  gatePassService,
  leaveService,
  minimumWagesService,
  offSiteWorkHoursService,
  overtimeService,
  pfReturnService,
  ptReturnService,
  statutoryReturnService,
  useAttendance,
  useAttendanceDevice,
  useAttendanceDevices,
  useAttendanceMonthlySummary,
  useAttendances,
  useDocument,
  useDocuments,
  useEmployeeEducation,
  useEmployeeEducations,
  useEmployeeFamilies,
  useEmployeeFamily,
  useEmployeeLetter,
  useEmployeeLetters,
  useEmployeeProfile,
  useEmployeeProfiles,
  useEmployeeRegistration,
  useEmployeeRegistrations,
  useESICReturn,
  useESICReturns,
  useGatePass,
  useGatePasses,
  useLeave,
  useLeaves,
  useMinimumWage,
  useMinimumWages,
  useOffSiteWorkHour,
  useOffSiteWorkHours,
  useOvertime,
  useOvertimes,
  usePaginatedEmployeeProfiles,
  usePaginatedPFReturns,
  usePFReturn,
  usePFReturns,
  usePTReturn,
  usePTReturns,
  useStatutoryReturn,
  useStatutoryReturns,
} from './hrd';
import { usePolicies, usePolicy, usePremium, usePremiums } from './insurance';
import {
  batchItemGodownService,
  ghsPrecautionaryStatementService,
  godownService,
  godownTransferService,
  gradeService,
  hazardCategoryService,
  hazardGhsClassService,
  hazardStatementService,
  itemCategoryService,
  itemCategoryV2Service,
  itemV2Service,
  physicalStockService,
  revisionService,
  stockGroupService,
  useBatchGodownItemAllocate,
  useBatchItemGodown,
  useBatchItemGodowns,
  useGhsPrecautionaryStatement,
  useGhsPrecautionaryStatements,
  useGodown,
  useGodowns,
  useGodownsTree,
  useGodownTransfer,
  useGodownTransfers,
  useGrade,
  useGrades,
  useHazardCategories,
  useHazardCategory,
  useHazardGhsClass,
  useHazardGhsClasses,
  useHazardStatement,
  useHazardStatements,
  useItem,
  useItemCategories,
  useItemCategory,
  useItems,
  useMRS,
  usePaginatedBatchItemGodowns,
  usePaginatedGodowns,
  usePaginatedHazardGhsClasses,
  usePaginatedHazardStatement,
  usePaginatedItemCategories,
  usePaginatedItems,
  usePaginatedPrecautionaryStatements,
  usePaginatedRevisions,
  usePaginatedStockGroups,
  usePhysicalStock,
  useRevision,
  useRevisions,
  useStockGroup,
  useStockGroups,
} from './inventory';
import {
  loanService,
  useAdvPaymentData,
  useDisbursementData,
  useDisbursementPostData,
  useLoan,
  useLoans,
  useRepaymentSchedule,
  useRepaymentSchedules,
  useSkipRepaymentData,
} from './loans';
import {
  notificationConfiguratorService,
  notificationService,
  useNotificationConfigurator,
  useNotificationConfigurators,
} from './notification';
import {
  bomFlowService,
  bomService,
  enquiryQuotesService,
  manufacturingVoucherService,
  purchaseEnquiryService,
  purchaseOrderFulfilmentService,
  purchaseOrderService,
  purchaseRequisitionService,
  salesEnquiryService,
  salesOrderService,
  useBom,
  useBomDependencies,
  useBomDependency,
  useBomFlow,
  useBomFlows,
  useBomProject,
  useBomProjects,
  useBoms,
  useClient,
  useClientItem,
  useClientItems,
  useClients,
  useEnquiries,
  useEnquiry,
  useEnquiryQuote,
  useEnquiryQuotes,
  useLeadCustomer,
  useLeadCustomerEnquiries,
  useLeadCustomerEnquiry,
  useLeadCustomers,
  useLeadEnquiries,
  useLeadEnquiry,
  useManufacturingVoucher,
  useManufacturingVouchers,
  useOrders,
  usePaginatedBomFlows,
  usePaginatedBomProjects,
  usePaginatedBoms,
  usePaginatedClients,
  usePaginatedEnquiries,
  usePaginatedLeadCustomerEnquiries,
  usePaginatedLeadCustomers,
  usePaginatedManufacturingVouchers,
  usePaginatedPurchaseEnquiries,
  usePaginatedPurchaseOrders,
  usePaginatedPurchaseRequisitions,
  usePaginatedSalesOrders,
  usePaginatedVendors,
  usePurchaseEnquiries,
  usePurchaseEnquiry,
  usePurchaseOrder,
  usePurchaseOrderFulfilment,
  usePurchaseOrderFulfilments,
  usePurchaseOrders,
  usePurchaseRequisition,
  usePurchaseRequisitions,
  useSalesOrder,
  useVendor,
  useVendorItem,
  useVendors,
  useVirtualItemAllocate,
  useVirtuallyReserveMfVoucher,
  useVirtualMfStock,
  useVirtualStock,
  vendorService,
  virtualManufacturingStockService,
  virtualStockService,
} from './order';
import {
  payrollReportsService,
  salaryRevisionService,
  salaryService,
  usePayrollReport,
  usePayrollReports,
  useSalaries,
  useSalary,
  useSalaryParam,
  useSalaryParams,
  useSalaryRevision,
  useSalaryRevisions,
} from './payroll';
import {
  empShiftScheduleService,
  locationHolidaysService,
  logRecordService,
  machineScheduleService,
  machineService,
  machineShiftService,
  rotationalShiftDayService,
  rotationalShiftScheduleService,
  shiftScheduleService,
  switchShiftScheduleService,
  useEmpShiftSchedule,
  useEmpShiftSchedules,
  useGetMachineSchedule,
  useLocationHoliday,
  useLocationHolidays,
  useLogRecords,
  useMachine,
  useMachines,
  useMachineSchedule,
  useMachineSchedules,
  useMachineShifts,
  usePaginatedLogRecords,
  useRotationalShiftDay,
  useRotationalShiftDays,
  useRotationalShiftSchedule,
  useRotationalShiftSchedules,
  useShiftSchedule,
  useShiftSchedules,
  useSwitchShift,
  useSwitchShifts,
} from './planning';
import {
  useChangeAssesment,
  useChangeAssesments,
  useClosureDatePeriod,
  useClosureDatePeriods,
} from './quality-assurance';
import {
  questionsService,
  topicService,
  useQuestion,
  useQuestionnaireQuestion,
  useQuestionnaireQuestions,
  useQuestionnaireSurveyAnswer,
  useQuestionnaireSurveyAnswers,
  useQuestionnaireTopic,
  useQuestionnaireTopics,
  useQuestions,
  useTopic,
  useTopics,
} from './questionnaire';
import { reportsService, useReport, useReports } from './reporting';
import {
  useSalaryAdvance,
  useSalaryAdvanceEligibleAmt,
  useSalaryAdvances,
} from './salary-advance';
import {
  useJob,
  useJobs,
  usePaginatedJobs,
  useTask,
  useTasks,
} from './scheduler';

export const config: Config = {
  mode: 'production',
  services: {
    dashboardService,
    loansService: loanService,
    userRendererService: new UserRenderer({
      fileService: fileTransferService,
      employeeService: employeeProfileService,
    }),
    revisionService,
    login: loginService,
    resetPassword: resetPasswordService,
    setPassword: setPasswordService,
    authorizationService,
    companyGroupService: companyGroupAdminService,
    locations: locationsService,
    roleAdmin: roleAdminService,
    userService: userAdminService,
    metricsService,
    gstService,
    deliveryTermsService,
    paymentTermsService,
    currencyService,
    godownService,
    stockGroupService,
    itemCategoryV2Service,
    itemCategoryService,
    fileTransferService,
    vendorService,
    hazardCategoryService,
    hazardStatementService,
    ghsPrecautionaryStatementService,
    hazardGhsClassService,
    employeeProfileService,
    employeeLettersService,
    statutoryReturnService,
    pfReturnService,
    minimumWagesService,
    ptReturnService,
    esicReturnService,
    employeeExitService,
    attendanceService,
    attendanceDeviceService,
    gatePassService,
    leaveService,
    overtimeService,
    offSiteWorkHoursService,
    itemV2Service,
    gradeService,
    bomService,
    physicalStockService,
    virtualStockService,
    batchItemGodownService,
    purchaseOrderService,
    purchaseRequisitionService,
    purchaseEnquiryService,
    enquiryQuotesService,
    purchaseOrderFulfilmentService,
    salesEnquiryService,
    virtualManufacturingStockService,
    manufacturingVoucherService,
    salesOrderService,
    godownTransferService,
    shiftScheduleService,
    empShiftScheduleService,
    locationHolidaysService,
    logRecordService,
    machineService,
    machineShiftService,
    machineScheduleService,
    daybookService,
    accountGroupService,
    ledgerService,
    notificationService,
    companyGroupSettingService,
    notificationActionService,
    topicService,
    questionsService,
    salaryService,
    salaryRevisionService,
    payrollReportsService,
    rotationalShiftScheduleService,
    rotationalShiftDayService,
    switchShiftScheduleService,
    bomFlowService,
    reportsService,
    notificationConfiguratorService,
  },
  hooks: createHooks(),
  providers: {
    location: LocationProvider,
    user: UserProvider,
  },
};

export function createHooks(): Hooks {
  return {
    useDashboard,
    useDashboards,
    useSalary,
    useSisterLocation,
    useSalaries,
    useSalaryRevision,
    useSalaryRevisions,
    useSalaryParam,
    useSalaryParams,
    usePayrollReport,
    usePayrollReports,
    usePaginatedPaymentTerms,
    usePaymentTerm,
    usePaymentTerms,
    useEmployeeCategories,
    usePaginatedEmployeeCategories,
    useEmployeeDesignations,
    usePaginatedEmployeeDesignations,
    useDeliveryTerm,
    useDeliveryTerms,
    usePaginatedDeliveryTerms,
    useUserAuthorization,
    useCurrentUser,
    useAuthorization,
    useAuthorizations,
    usePaginatedAuthorizations,
    useLogin,
    useLogout,
    useResetPassword,
    useSetPassword,
    useCurrentCompanyGroup,
    useCompanyGroupAdmin,
    useLocations,
    useCurrentLocation,
    useLocationAdmin,
    useRoleAdmin,
    useGetAllRoles,
    usePaginatedRoles,
    useModules,
    useModuleAdmin,
    useGhsPrecautionaryStatement,
    useGhsPrecautionaryStatements,
    usePaginatedPrecautionaryStatements,
    useHazardGhsClasses,
    useHazardGhsClass,
    useHazardCategories,
    useHazardCategory,
    useHazardStatement,
    useHazardStatements,
    usePaginatedHazardStatement,
    useResourceAdmin,
    useResourcesAdmin,
    usePaginatedResourcesAdmin,
    useActions,
    useActionAdmin,
    useCurrentCompany,
    useCompanies,
    useCompanyAdmin,
    useUserAdmin,
    useUsers,
    useUserRoles,
    useGodownsTree,
    useVendor,
    useVendors,
    usePaginatedVendors,
    useVendorItem,
    useEmployeeProfile,
    useEmployeeProfiles,
    usePaginatedEmployeeProfiles,
    useStatutoryReturn,
    useStatutoryReturns,
    useMinimumWage,
    useMinimumWages,
    usePFReturn,
    usePFReturns,
    usePaginatedPFReturns,
    usePTReturn,
    usePTReturns,
    useESICReturn,
    useESICReturns,
    useItem,
    useItems,
    useGrade,
    useGrades,
    usePaginatedItems,
    useBoms,
    usePaginatedBoms,
    useBom,
    usePhysicalStock,
    useVirtualStock,
    usePurchaseRequisition,
    usePurchaseRequisitions,
    usePaginatedPurchaseRequisitions,
    usePaginatedPurchaseOrders,
    usePurchaseEnquiry,
    usePaginatedPurchaseEnquiries,
    usePurchaseEnquiries,
    useBatchItemGodown,
    usePaginatedManufacturingVouchers,
    useBatchItemGodowns,
    usePaginatedBatchItemGodowns,
    useEnquiryQuote,
    useEnquiryQuotes,
    usePaginatedEnquiries,
    usePurchaseOrder,
    usePurchaseOrders,
    useBatchGodownItemAllocate,
    usePurchaseOrderFulfilment,
    usePurchaseOrderFulfilments,
    useVirtualItemAllocate,
    useMRS,
    useGodownTransfer,
    useGodownTransfers,
    useStockGroup,
    useStockGroups,
    usePaginatedStockGroups,
    useGodown,
    useGodowns,
    usePaginatedGodowns,
    useBomFlow,
    useBomFlows,
    usePaginatedBomFlows,
    useBomDependencies,
    useBomDependency,
    useShiftSchedule,
    useShiftSchedules,
    useEmpShiftSchedule,
    useEmpShiftSchedules,
    useLocationHoliday,
    useLocationHolidays,
    useEnquiry,
    useEnquiries,
    useClients,
    useClient,
    useClientItems,
    useClientItem,
    useLeadCustomer,
    useLeadCustomers,
    usePaginatedLeadCustomers,
    useLeadCustomerEnquiry,
    useLeadCustomerEnquiries,
    usePaginatedLeadCustomerEnquiries,
    useVirtualMfStock,
    useManufacturingVouchers,
    useManufacturingVoucher,
    useVirtuallyReserveMfVoucher,
    useSalesOrder,
    useOrders,
    usePaginatedSalesOrders,
    useMachines,
    useMachineShifts,
    useMachine,
    usePublicTransfer,
    useFileTransfer,
    useMachineSchedules,
    useMachineSchedule,
    useGetMachineSchedule,
    useLogRecords,
    usePaginatedLogRecords,
    useCompanyGroupSetting,
    useCompanyGroupSettings,
    useBomEstimate,
    useHrdDashboardReport,
    useNotificationAction,
    useNotificationActions,
    useItemCategories,
    useCurrencies,
    usePaginatedCurrencies,
    useMetrics,
    usePaginatedMetrics,
    useGsts,
    usePaginatedUsers,
    usePaginatedItemCategories,
    usePaginatedGST,
    usePaginatedHazardGhsClasses,
    usePaginatedClients,
    useDaybook,
    useDaybooks,
    useAccountGroup,
    useAccountGroups,
    useLedger,
    useLedgers,
    usePaginatedDaybook,
    useAttendances,
    useAttendance,
    useAttendanceMonthlySummary,
    useAttendanceDevices,
    useAttendanceDevice,
    useGatePass,
    useGatePasses,
    useLeave,
    useLeaves,
    useOvertime,
    useOvertimes,
    useCurrentUserRoles,
    useTopic,
    useTopics,
    useOffSiteWorkHour,
    useOffSiteWorkHours,
    useQuestion,
    useQuestions,
    useRotationalShiftSchedules,
    useRotationalShiftSchedule,
    useRotationalShiftDay,
    useRotationalShiftDays,
    useSwitchShift,
    useSwitchShifts,
    useQuestionnaireTopic,
    useQuestionnaireTopics,
    useQuestionnaireQuestion,
    useQuestionnaireQuestions,
    useQuestionnaireSurveyAnswer,
    useQuestionnaireSurveyAnswers,
    useMisconduct,
    useMisconducts,
    useMisconductRuleBook,
    useMisconductRuleBooks,
    useRuleRequest,
    useRuleRequests,
    useSalaryAdvance,
    useSalaryAdvances,
    useSalaryAdvanceEligibleAmt,
    usePolicy,
    usePolicies,
    usePremium,
    usePremiums,
    useLoan,
    useLoans,
    useRepaymentSchedule,
    useRepaymentSchedules,
    useDisbursementData,
    useDisbursementPostData,
    useSkipRepaymentData,
    useAdvPaymentData,
    useCurrency,
    useItemCategory,
    useMetric,
    useGst,
    useEmployeeDesignation,
    useEmployeeCategory,
    useEmployeeRegistrations,
    useEmployeeRegistration,
    useEmployeeEducation,
    useEmployeeEducations,
    useEmployeeFamily,
    useEmployeeFamilies,
    useDocuments,
    useDocument,
    useTasks,
    useTask,
    useJobs,
    useJob,
    usePaginatedJobs,
    useTemplates,
    useTemplate,
    useReport,
    useReports,
    useNotificationConfigurator,
    useNotificationConfigurators,
    useDepartment,
    useDepartments,
    useBomProject,
    useBomProjects,
    usePaginatedBomProjects,
    useOnboardEmployee,
    useOnboardEmployees,
    useRevision,
    useRevisions,
    usePaginatedRevisions,
    useAppearance,
    useAppearances,
    usePaginatedAppearances,
    useOdour,
    useOdours,
    usePaginatedOdours,
    useColor,
    useColors,
    usePaginatedColors,
    useHsn,
    useHsns,
    usePaginatedHsns,
    useEmployeeLetter,
    useEmployeeLetters,
    useChangeAssesment,
    useChangeAssesments,
    useClosureDatePeriod,
    useClosureDatePeriods,
    useVoucher,
    useVouchers,
    useVoucherTransaction,
    useVoucherTransactions,
    useLeadEnquiries,
    useLeadEnquiry,
  };
}
