import {
  EmployeeProfileType,
  PremiumsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  RepaymentScheduleFilter,
  RepaymentScheduleType,
} from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../../contexts/location';
import { UserContext } from '../../../../contexts/user';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseSalaryParams } from '../../../../hooks/hrd/salary-parameter/use-salary-params';
import { UseSalaryRevision } from '../../../../hooks/hrd/salary-revision/use-salary-revision';
import { SalaryRevisionInterface } from '../../../../models/interfaces/hrd/salary-revision';
import { LoanV2Type } from '../../loan-management/types/loan-v2-type';
import { calculateESICDates } from '../util/esic-calculator';

export type CreateSalaryRevisionProps = {
  id?: string;
  parentRevision?: number;
  parentId?: string;
  salaryRevisions: Array<SalaryRevisionType>;
  getLoans: (filter?: LoanFilter | undefined) => Promise<LoanV2Type[]>;
  getRepaymentSchedules: (
    filter?: RepaymentScheduleFilter
  ) => Promise<RepaymentScheduleType[]>;
  salaryRevisionService: SalaryRevisionInterface;
};

export function createSalaryRevision({
  employee,
  useSalaryRevision,
  onClose,
  selectedParams,
  misconducts,
  salaryAdvances,
  premiums,
  useSalaryParams,
  useEmployeeCategories,
}: {
  employee: EmployeeProfileType;
  useSalaryRevision: UseSalaryRevision;
  selectedParams: SalaryParamType[];
  onClose: () => void;
  misconducts: MisconductType[];
  salaryAdvances: SalaryAdvanceType[];
  premiums: PremiumsType[];
  useSalaryParams: UseSalaryParams;
  useEmployeeCategories: UseEmployeeCategories;
}): ({
  id,
  parentRevision,
  parentId,
  salaryRevisions,
  salaryRevisionService,
}: CreateSalaryRevisionProps) => JSX.Element {
  const date = moment().format('YYYY-MM-DD');
  const monthDetails = {
    month: date
      ? parseInt(moment(date, 'YYYY-MM-DD').format('MM'))
      : moment().subtract(1, 'M').month(),
    year: date
      ? moment(date, 'YYYY-MM-DD').year()
      : moment().subtract(1, 'M').year(),
    monthName: date
      ? moment(date, 'YYYY-MM-DD').format('MMM')
      : moment().subtract(1, 'M').format('MMM'),
    totalDays: date
      ? moment(date, 'YYYY-MM-DD').daysInMonth()
      : moment().subtract(1, 'M').daysInMonth(),
    totalOfficialDays: date
      ? moment(date, 'YYYY-MM-DD').daysInMonth()
      : moment().subtract(1, 'M').daysInMonth(),
  };
  return function Add({
    id,
    parentRevision,
    parentId,
    salaryRevisions,
    getLoans,
    getRepaymentSchedules,
    salaryRevisionService,
  }: CreateSalaryRevisionProps): JSX.Element {
    const parentSalRevision =
      salaryRevisions?.find((x) => parentId && x.id === parentId) || null;
    // const [cgSetting, setCgSetting] = useState<CompanyGroupSetting | null>();

    const { user: currentUser } = useContext(UserContext);
    const { cgSetting } = useContext(LocationContext);

    // const { getAllSync } = useCompanyGroupSettings();

    // const getData = async () => {
    //   const cgs = await getAllSync();
    //   if (cgs.length > 0) setCgSetting(cgs[0]);
    // };

    // useEffect(() => {
    //   getData();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const {
      syncSet: setSalaryRevision,
      getSync: getSalaryRevisionSync,
    } = useSalaryRevision();
    const [approvedBy, setApprovedBy] = useState<{ id: string; name: string }>({
      id: '',
      name: '',
    });
    const [initialData, setInitialData] = useState<any>({
      revisionNum: parentRevision ? parentRevision + 1 : '1',
      prevRevisionId: parentId || '',
      date: '',
      // name: '',
      // hra: 10000,
      ctcAnnual: 0,
      skillLevel: '', // profile
      isRetentionApplicable: 'No', // salary revision
      retentionBy: '',
      retentionOf: '',
      retentionByType: '',
      retentionEndDate: '',
      advanceBonusPayout: '',
      incomeTax: 0, // not outer dep
      epsContributionPercent: '',
      capWagesForPfContribution: 'No',
      nextRevisionDate: '',
      salaryParamType: { id: '', name: '' },
      grade: { id: '', name: '' },
      advanceBonusPayoutType: '',
    });

    useEffect(() => {
      if (id) {
        getSalaryRevisionSync(id).then((res) => {
          setApprovedBy(res.approvedBy);
          const newInitialData = {
            ...initialData,
            ...{
              date: res.date,
              // name: res.name,
              revisionNum: res.revisionNum,
              prevRevisionId: res.prevRevisionId,
              ...res.details,
            },
          };

          setInitialData(newInitialData);
        });
      }
      // eslint-disable-next-line
    }, []);

    async function submit(res) {
      const selectedParam = selectedParams.find(
        (x) => x.name === employee.details.salaryParam?.name
      );

      const formData = { ...initialData, ...res };
      const parentSalaryRevision = res.prevRevisionId
        ? salaryRevisions.find((x) => x.id === res.prevRevisionId)
        : undefined;
      delete formData.salParams;
      delete formData.esicApplicability;
      // const round1 = await calculate({
      //   employee,
      //   selectedParams,
      //   formData,
      //   cgSetting,
      //   parentSalaryRevision,
      //   monthDetails,
      //   misconducts,
      //   salaryAdvances,
      //   premiums,
      //   getLoans,
      //   getRepaymentSchedules,
      // });

      const round1 = await salaryRevisionService.computeESICAndParams({
        employeeId: employee.id,
        companyGroupId: employee.companyGroup.id,
        salaryParamId: selectedParam ? selectedParam.id : '',
        parentSalaryRevisionId: parentSalaryRevision?.id,
        formData: formData,
        month: formData.date || date,
      });

      console.log('round1 result', round1.salParams);
      formData.salParams = round1.salParams;
      formData.esicApplicability = round1.esic;
      console.log('round 2');
      // const { esic, salParams } = await calculate({
      //   employee,
      //   selectedParams,
      //   formData,
      //   cgSetting,
      //   parentSalaryRevision,
      //   monthDetails,
      //   misconducts,
      //   salaryAdvances,
      //   premiums,
      //   getLoans,
      //   getRepaymentSchedules,
      // });

      const {
        esic,
        salParams,
      } = await salaryRevisionService.computeESICAndParams({
        employeeId: employee.id,
        companyGroupId: employee.companyGroup.id,
        salaryParamId: selectedParam ? selectedParam.id : '',
        parentSalaryRevisionId: parentSalaryRevision?.id,
        formData: formData,
        month: formData.date || date,
      });

      const increment =
        parseFloat(`${res.ctcAnnual || '0'}`) -
        parseFloat(`${parentSalRevision?.details?.ctcAnnual || '0'}`);

      const data = {
        name: res.name,
        revisionNum: res.revisionNum,
        prevRevisionId: res.prevRevisionId,
        approvedBy: !approvedBy.id ? currentUser : approvedBy,
        date: res.date,
        // eslint-disable-next-line
        details: {
          ctcAnnual: res.ctcAnnual,
          salaryParamType: res.salaryParamType,
          grade: res.grade,
          increment: increment,
          skillLevel: res.skillLevel,
          isRetentionApplicable: res.isRetentionApplicable, // parseInt(res.retention), // salary revision
          retentionByType: res.retentionByType,
          retentionBy: res.retentionBy || '',
          retentionOf: res.retentionOf || '',
          retentionEndDate: res.retentionEndDate || '',
          advanceBonusPayoutType: res.advanceBonusPayoutType,
          advanceBonusPayout: res.advanceBonusPayout,
          incomeTax: parseInt(res.incomeTax), // not outer dep
          notes: res.notes,
          nextRevisionDate: res.nextRevisionDate,
          capWagesForPfContribution: res.capWagesForPfContribution,
          epsContributionPercent: res.epsContributionPercent,
          esicApplicability: esic,
          salParams: salParams,
        },
        employee: {
          id: employee.id,
          name: employee.name,
        },
      } as SalaryRevisionType;
      if (id) {
        data.id = id;
      }
      data.name = `${data.employee.name}-${data.date}-v${data.revisionNum}`;
      console.log(data);

      const result = await setSalaryRevision(data);
      console.log(result);
      onClose();
    }

    const formProps: FormProps<any> = {
      dynamicContent: {
        left: async (formData) => {
          const parentSalaryRevision = formData.prevRevisionId
            ? salaryRevisions.find((x) => x.id === formData.prevRevisionId)
            : undefined;
          const dates = calculateESICDates(cgSetting);

          console.log('ye bheja', {
            employee,
            selectedParams,
            formData: { ...initialData, ...formData },
            cgSetting,
            parentSalaryRevision,
            monthDetails,
            misconducts,
            salaryAdvances,
            premiums,
            getLoans,
            getRepaymentSchedules,
          });

          // Calculate should know the purpose: BasicDACompute
          const formToSend = { ...initialData, ...formData };
          delete formToSend.salParams;
          console.log('round1', formToSend);
          // const { esic: calculatedEsicApplicable, salParams } = await calculate({
          //   employee,
          //   selectedParams,
          //   formData: formToSend,
          //   cgSetting,
          //   parentSalaryRevision,
          //   monthDetails,
          //   misconducts,
          //   salaryAdvances,
          //   premiums,
          //   getLoans,
          //   getRepaymentSchedules,
          // });

          const selectedParam = selectedParams.find(
            (x) => x.name === employee.details.salaryParam?.name
          );

          const {
            esic: calculatedEsicApplicable,
            salParams,
          } = await salaryRevisionService.computeESICAndParams({
            employeeId: employee.id,
            companyGroupId: employee.companyGroup.id,
            salaryParamId: selectedParam ? selectedParam.id : '',
            parentSalaryRevisionId: parentSalaryRevision?.id,
            formData: formToSend,
            month: formToSend.date || date,
          });

          // const qaz = await salaryRevisionService.computeESICAndParams({
          //   employeeId: employee.id,
          //   companyGroupId: employee.companyGroup.id,
          //   salaryParamId: selectedParam ? selectedParam.id : '',
          //   parentSalaryRevisionId: parentSalaryRevision?.id,
          //   formData: formToSend,
          //   month: formToSend.date,
          // });
          // console.log('from server', qaz);

          console.log('ye mila round 1', {
            esic: calculatedEsicApplicable,
            salParams,
          });

          const previousCTC = parseFloat(
            (parentSalaryRevision?.details?.ctcAnnual as string) || '0'
          );
          const currentCTC = parseFloat((formData.ctcAnnual as string) || '0');
          const differenceType: 'increment' | 'decrement' =
            currentCTC < previousCTC ? 'decrement' : 'increment';
          const increment =
            differenceType === 'increment'
              ? currentCTC - previousCTC
              : previousCTC - currentCTC;

          return (
            <div>
              <div className='m-1 p-1 border border-gray-200 rounded-md'>
                <div className='flex flex-wrap'>
                  <div className='w-1/3'>
                    <span className='font-semibold'>Grade : </span>
                    {employee.details?.grade.name}
                  </div>
                  <div className='w-1/3'>
                    <span className='font-semibold'>Department : </span>
                    {employee.details?.department}
                  </div>
                  <div className='w-1/3'>
                    <span className='font-semibold'>Designation : </span>
                    {employee.details?.designation}
                  </div>
                </div>
              </div>
              <div className='m-1 p-1 border border-gray-200 rounded-md'>
                <div className='flex flex-wrap'>
                  <div className='w-1/2'>
                    <span className='font-semibold'>Lower Threshold : </span>
                    {cgSetting?.details?.hrd?.salaryRules?.lowerESICThreshold}
                  </div>
                  <div className='w-1/2'>
                    <span className='font-semibold'>Upper Threshold : </span>
                    {cgSetting?.details?.hrd?.salaryRules?.upperESICThreshold}
                  </div>
                  <div className='w-1/2'>
                    <span className='font-semibold'>Cut Off Dates :</span>{' '}
                    {dates.map((x, idx) => {
                      return (
                        <span
                          className='bg-gray-100 px-0.5 mx-0.5 border rounded-lg'
                          key={idx}
                        >
                          {x}
                        </span>
                      );
                    })}
                  </div>
                  <div className='w-1/2'>
                    <span className='font-semibold'>ESIC Applicable: </span>
                    {calculatedEsicApplicable.data.value}{' '}
                    {calculatedEsicApplicable.data.until
                      ? `(until ${calculatedEsicApplicable.data.until} )`
                      : ''}
                    {/* <span className='text-red-500 animate-pulse'>
                      From Server: {qaz?.esic?.data?.value}{' '}
                      {calculatedEsicApplicable.data.until
                        ? `(until ${qaz?.esic?.data?.until} )`
                        : ''}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className='m-1 p-1 border border-gray-200 rounded-md'>
                <div className='flex'>
                  <div className='font-semibold mx-2'>
                    <div>Topic</div>
                    <div>Revision #</div>
                    <div>Salary Param</div>
                    <div>Grade</div>
                    <div>Effective date</div>
                    <div>Annual CTC</div>
                    <div>Skill Level</div>
                    <div>Retention Applicable</div>
                    <div>Advance Bonus Payout</div>
                    <div>Income Tax</div>
                    <div>Cap Wages For PF Contribition</div>
                    <div>EPS Contribution Percent</div>
                    <div>Basic DA (For Sal Rev)</div>
                    <div>Notes</div>
                  </div>
                  <div className='text-red-500 mx-2'>
                    <div>Previous Salary Revision</div>
                    <div>{parentSalaryRevision?.revisionNum}&nbsp;</div>
                    <div>
                      {parentSalaryRevision?.details?.salaryParamType?.name}
                      &nbsp;
                    </div>
                    <div>
                      {parentSalaryRevision?.details?.grade?.name}&nbsp;
                    </div>
                    <div>
                      {parentSalaryRevision?.date} to{' '}
                      {parentSalaryRevision?.details?.nextRevisionDate || '-'}
                      &nbsp;
                    </div>
                    <div>{parentSalaryRevision?.details?.ctcAnnual}&nbsp;</div>
                    <div>{parentSalaryRevision?.details?.skillLevel}&nbsp;</div>
                    <div>
                      {parentSalaryRevision?.details?.isRetentionApplicable}
                      {parentSalaryRevision?.details?.isRetentionApplicable ===
                      'Yes'
                        ? ` -> ${
                            parentSalaryRevision?.details?.retentionByType ||
                            'type'
                          } ${
                            parentSalaryRevision?.details?.retentionBy ||
                            'value'
                          } ${
                            parentSalaryRevision?.details?.retentionOf || ''
                          } untill ${
                            parentSalaryRevision?.details?.retentionEndDate ||
                            'date'
                          }`
                        : ''}
                      &nbsp;
                    </div>
                    <div>
                      {parentSalaryRevision?.details?.advanceBonusPayout}{' '}
                      {parentSalRevision?.details?.advanceBonusPayoutType}&nbsp;
                    </div>
                    <div>{parentSalaryRevision?.details?.incomeTax}&nbsp;</div>
                    <div>
                      {parentSalaryRevision?.details?.capWagesForPfContribution}
                      &nbsp;
                    </div>
                    <div>
                      {parentSalaryRevision?.details?.epsContributionPercent}
                      &nbsp;
                    </div>
                    <div>
                      {
                        parentSalaryRevision?.details?.salParams?.other
                          ?.basicDaForSalRev
                      }
                      &nbsp;
                    </div>
                    <div>{parentSalaryRevision?.details?.notes}&nbsp;</div>
                  </div>
                  <div className='text-green-500 mx-2'>
                    <div>Current Salary Revision</div>
                    <div>{(formData.revisionNum as any) || ' '}&nbsp;</div>
                    <div>{(formData?.salaryParamType as any)?.name}&nbsp;</div>
                    <div>{(formData?.grade as any)?.name}&nbsp;</div>
                    <div>
                      {(formData.date as string) || ' '} to{' '}
                      {(formData.nextRevisionDate as string) || '-'}&nbsp;
                    </div>
                    <div>
                      {(formData.ctcAnnual as string) || ' '} (
                      {
                        <span
                          className={
                            increment
                              ? 'text-green-600 animate-pulse'
                              : 'text-red-600 animate-pulse'
                          }
                        >
                          {differenceType} of {increment}
                        </span>
                      }
                      )&nbsp;
                    </div>
                    <div>{(formData.skillLevel as string) || ' '}&nbsp;</div>
                    <div>
                      {(formData.isRetentionApplicable as string) || ' '}
                      {formData.isRetentionApplicable === 'Yes'
                        ? ` -> ${formData?.retentionByType || 'type'} ${
                            formData?.retentionBy || 'value'
                          } ${formData?.retentionOf || ''} untill ${
                            formData?.retentionEndDate || 'date'
                          }`
                        : ''}
                      &nbsp;
                    </div>
                    <div>
                      {(formData.advanceBonusPayout as string) || ' '}{' '}
                      {(formData.advanceBonusPayoutType as string) || ''}&nbsp;
                    </div>
                    <div>{(formData.incomeTax as string) || ' '}&nbsp;</div>
                    <div>
                      {(formData.capWagesForPfContribution as string) || ' '}
                      &nbsp;
                    </div>
                    <div>
                      {(formData.epsContributionPercent as string) || ' '}
                      &nbsp;
                    </div>
                    <div>
                      {salParams.other?.basicDaForSalRev}
                      {/* <span className='mx-1 text-red-500 animate-pulse'>
                        From Server: {qaz?.salParams?.other?.basicDaForSalRev}
                      </span> */}
                      &nbsp;
                    </div>
                    <div>{(formData.notes as string) || ' '}&nbsp;</div>
                  </div>
                </div>
              </div>

              <div className='m-1 p-1 border border-gray-200 rounded-md text-center'>
                <div>
                  <span className='font-semibold'>
                    Calculated ESIC Applicability:{' '}
                  </span>
                  {calculatedEsicApplicable.value}
                  {calculatedEsicApplicable.data?.until
                    ? ` (${calculatedEsicApplicable.data.value} until ${calculatedEsicApplicable.data.until})`
                    : null}
                  <span className='font-semibold ml-3'>
                    Salary Route Taken:{' '}
                  </span>
                  {calculatedEsicApplicable.salRoute}
                </div>
                <div className='italic'>
                  <span className='font-semibold'>Reason: </span>
                  {calculatedEsicApplicable.reason}
                </div>
                {/* <div className='text-red-500 animate-pulse'>
                  <span className='font-semibold'>
                    From Server: Calculated ESIC Applicability:{' '}
                  </span>
                  {qaz?.esic?.value}
                  <span className='font-semibold ml-3'>Salary Route Taken: </span>
                  {qaz?.esic?.salRoute}
                </div>
                <div className='italic text-red-500 animate-pulse'>
                  <span className='font-semibold'>Reason: </span>
                  {qaz?.esic?.reason}
                </div> */}
              </div>
            </div>
          );
        },
      },
      fieldsData: [
        {
          groupName: '1. General',
          property: 'revisionNum',
          type: 'number',
          label: 'Revision',
          required: true,
          readonly: true,
        },
        {
          groupName: '1. General',
          property: 'salaryParamType',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useSalaryParams,
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '1. General',
          property: 'grade',
          required: true,
          type: 'searchable-select',
          searchOptions: {
            useSearch: useEmployeeCategories,
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '1. General',
          property: 'skillLevel',
          type: 'select',
          label: 'Skill level',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Skilled', value: 'Skilled' },
            { text: 'Semi-Skilled', value: 'Semi-Skilled' },
            { text: 'Unskilled', value: 'Unskilled' },
          ],
        },
        {
          groupName: '1. General',
          property: 'date',
          type: 'date',
          label: 'Effective Date ',
          required: true,
        },
        {
          groupName: '2. Earning',
          property: 'ctcAnnual',
          type: 'number',
          label: 'Annual CTC',
          required: true,
        },
        {
          groupName: '2. Earning',
          property: 'isRetentionApplicable',
          label: 'Is Retention Applicable',
          type: 'select',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
        },
        {
          groupName: '2. Earning',
          property: 'retentionByType',
          label: 'Retention By Type',
          dependentOn: (formData) => {
            if (formData.isRetentionApplicable === 'Yes') {
              return true;
            }
            return false;
          },
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'RetentionByPercent', value: 'RetentionByPercent' },
            { text: 'RetentionByValue', value: 'RetentionByValue' },
          ],
        },
        {
          groupName: '2. Earning',
          property: 'retentionBy',
          label: 'Retention By',
          type: 'number',
          dependentOn: (formData) => {
            if (
              formData.isRetentionApplicable === 'Yes' &&
              formData.retentionByType
            ) {
              return true;
            }
            return false;
          },
        },
        {
          groupName: '2. Earning',
          property: 'retentionOf',
          label: 'Retention Calculated Of',
          type: 'select',
          dependentOn: (formData) => {
            if (
              formData.isRetentionApplicable === 'Yes' &&
              formData.retentionByType === 'RetentionByPercent'
            ) {
              return true;
            }
            return false;
          },
          options: [
            { text: 'Select', value: '' },
            { text: 'Basic-DA', value: 'BasicDA' },
            { text: 'CTC', value: 'CTC' },
          ],
        },
        {
          groupName: '2. Earning',
          property: 'retentionEndDate',
          type: 'date',
          label: 'Retention End Date ',
          dependentOn: (formData) => {
            if (formData.isRetentionApplicable === 'Yes') {
              return true;
            }
            return false;
          },
        },
        {
          groupName: '2. Earning',
          property: 'advanceBonusPayoutType',
          label: 'Advance Bonus Payout Type',
          type: 'select',
          options: [
            { text: 'NA', value: 'NA' },
            { text: 'Auto', value: 'Auto' },
            { text: 'Monthly', value: 'Monthly' },
            { text: 'Quarterly', value: 'Quarterly' },
          ],
        },
        {
          groupName: '2. Earning',
          property: 'advanceBonusPayout',
          label: 'Advance Bonus Payout',
          dependentOn: (formData) => {
            if (
              formData.advanceBonusPayoutType &&
              formData.advanceBonusPayoutType !== 'NA'
            ) {
              return true;
            }
            return false;
          },
          type: 'input',
        },
        {
          groupName: '3. Deduction',
          property: 'incomeTax',
          type: 'input',
        },
        {
          groupName: '3. Deduction',
          property: 'capWagesForPfContribution',
          type: 'select',
          label: 'Cap Wages For PF Contribution',
          options: [
            { text: 'Select', value: '' },
            { text: 'No', value: 'No' },
            { text: 'Yes', value: 'Yes' },
            {
              text: 'Cap Employers Contribution Only',
              value: 'Cap Employers Contribution Only',
            },
          ],
        },
        {
          groupName: '3. Deduction',
          property: 'epsContributionPercent',
          label: 'EPS Contribution Percent',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: '8.33 Percent', value: '8.33' },
            { text: '9.49 Percent', value: '9.49' },
          ],
        },
        {
          groupName: '1. General',
          property: 'nextRevisionDate',
          label: 'Next Revision Date',
          type: 'date',
          required: true,
        },
        {
          groupName: '1. General',
          property: 'notes',
          label: 'Notes',
          type: 'input',
        },
      ],
      initialFormState: initialData,
      onSubmit: async (res) => {
        submit(res);
      },
      style: 'w-1/3',
      mapTToU: (t) => {
        return {
          ...initialData,
          ...t,
        };
      },
      button: {
        text: 'Submit',
      },
    };

    const Form = renderFormV2(formProps);

    return (
      <div>
        <div className='text-center'>
          {id ? 'Edit' : 'Add'} Salary Revision {employee.name}
        </div>
        <div>
          <Form />
        </div>
      </div>
    );
  };
}
