import { Account } from '@erp_core/erp-icons/icons/web/account';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { manufacturingVoucherService } from '../../config/order';
import { UseMetrics } from '../../hooks/admin/constants/metrics/use-metrics';
import { UseUsers } from '../../hooks/admin/user-admin/use-users-admin';
import { UseEmployeeProfiles } from '../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseBatchItemAllocate } from '../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseItems } from '../../hooks/inventory/item/use-items';
import { UseClient } from '../../hooks/order/sales/client/use-client';
import { UseClients } from '../../hooks/order/sales/client/use-clients';
import { UseSalesEnquiries } from '../../hooks/order/sales/enquiry/use-enquiries';
import { UseSalesEnquiry } from '../../hooks/order/sales/enquiry/use-enquiry';
import { UsePaginatedEnquiries } from '../../hooks/order/sales/enquiry/use-paginated-enquiries';
import { UseLeadCustomerEnquiries } from '../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiries';
import { UseLeadCustomerEnquiry } from '../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiry';
import { UsePaginatedLeadCustomerEnquiries } from '../../hooks/order/sales/lead-customer-enquiry/use-paginated-lead-customer-enquiries';
import { UseLeadCustomer } from '../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../hooks/order/sales/lead-customer/use-lead-customers';
import { UseLeadEnquiries } from '../../hooks/order/sales/lead-enquiry/use-lead-enquiries';
import { UseSalesOrder } from '../../hooks/order/sales/order/use-order';
import { UseManufacturingVouchers } from '../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { UserRendererInterface } from '../common/fragments/user';
import renderSalesItemEnquiryDetails from './item-enquiry';
import renderSalesLeadEnquiryDetails from './lead-enquiry';
import renderSalesEnquiryDetails from './sales-enquiry';
import { renderSalesEnquiryLeadDetailsV2 } from './sales-enquiry/sales-enquiry-lead-details-v2';

type RenderSalesMastersProps = {
  useEnquiries: UseSalesEnquiries;
  usePhysicalStock: UsePhysicalStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useManufacturingVouchers: UseManufacturingVouchers;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
  useEnquiry: UseSalesEnquiry;
  useItems: UseItems;
  useClient: UseClient;
  useClients: UseClients;
  useOrder: UseSalesOrder;
  usePaginatedEnquiries: UsePaginatedEnquiries;
  useUsers: UseUsers;
  useEmployeeProfiles: UseEmployeeProfiles;
  useMetrics: UseMetrics;
  useLeadCustomer: UseLeadCustomer;
  useLeadCustomers: UseLeadCustomers;
  useLeadCustomerEnquiry: UseLeadCustomerEnquiry;
  useLeadCustomerEnquiries: UseLeadCustomerEnquiries;
  usePaginatedLeadCustomerEnquiries: UsePaginatedLeadCustomerEnquiries;
  userRendererService: UserRendererInterface;
  useLeadEnquiries: UseLeadEnquiries;
};

export function renderSalesMasters({
  useEnquiries,
  usePhysicalStock,
  useBatchItemGodowns,
  useBatchGodownItemAllocate,
  useVirtualMfStock,
  useManufacturingVouchers,
  useVirtuallyReserveMfVoucher,
  useEnquiry,
  useItems,
  useClient,
  useClients,
  useOrder,
  usePaginatedEnquiries,
  useUsers,
  useEmployeeProfiles,
  useMetrics,
  useLeadCustomer,
  useLeadCustomers,
  useLeadCustomerEnquiry,
  useLeadCustomerEnquiries,
  usePaginatedLeadCustomerEnquiries,
  userRendererService,
  useLeadEnquiries,
}: RenderSalesMastersProps): () => JSX.Element {
  return function SalesMasters(): JSX.Element {
    const NewTabs = renderTabsV2Component();

    const SalesEnquiryDetailsV2 = renderSalesEnquiryDetails({
      hooks: {
        useEnquiries,
        useEnquiry,
        useItems,
        useClients,
        useOrder,
        usePaginatedEnquiries,
        useUsers,
      },
      services: {
        manufacturingVoucherService,
        userRendererService,
      },
    });

    const SalesEnquiryDetailsNewV2 = renderSalesEnquiryLeadDetailsV2({
      hooks: {
        useItems,
        useUsers,
        useEmployeeProfiles,
        useMetrics,
        useLeadCustomer,
        useLeadCustomers,
        useLeadCustomerEnquiry,
        useLeadCustomerEnquiries,
        usePaginatedLeadCustomerEnquiries,
        useClient,
        useClients,
      },
      services: {
        manufacturingVoucherService,
        userRendererService,
      },
    });

    const LeadEnquiryDetails = renderSalesLeadEnquiryDetails({
      useLeadEnquiries,
      useClients,
      useItems,
    });

    const SalesEnquiryItemsDetailsV2 = renderSalesItemEnquiryDetails({
      useEnquiries,
      usePhysicalStock,
      useBatchItemGodowns,
      useBatchGodownItemAllocate,
      useVirtualMfStock,
      useManufacturingVouchers,
      useVirtuallyReserveMfVoucher,
    });

    const newCategories: DataArray = [
      { name: 'sales-enquiries', Fn: SalesEnquiryDetailsV2 },
      { name: 'sales-enquiries-v2', Fn: SalesEnquiryDetailsNewV2 },
      { name: 'lead-enquiries', Fn: LeadEnquiryDetails },
      { name: 'item-enquiries', Fn: SalesEnquiryItemsDetailsV2 },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      icon: (
        <Account className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      title: 'Sales Enquiries',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
