import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { useContext, useState } from 'react';
// import { LoadingButton } from '@erp_core/erp-ui-components';
import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { Company } from '@erp_core/erp-types/dist/modules/admin';
// import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
// import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
// import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
// import { RepaymentScheduleFilter } from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { SalaryInterface } from '../../../../models/interfaces/hrd/salary';
// import { LoanV2Type } from '../../loan-management/types/loan-v2-type';
// import { appendOuterDependencies } from '../utils/compute-dynamic-data';
import { LocationContext } from '../../../../contexts/location';
import { findParamType, formatParam } from '../utils/generate-salary';
// import { appendFinalSalaryParam } from './salary-view';

type Report = {
  employee: string;
  employeeId: string;
  salaryRevision: string;
  employeeTakeHome: string;
  adjustedPayableDays: string;
  employeeGrossEarnings: string;
  salaryGenerated: string;
  reason: string;
  ctcAnnual: string;
  totalCtc: string;
  esic: string;
};

export function BulkSimulate({
  filteredEmployees,
  date,
  salaryRevisions,
  selectedParams,
  currentCompany,
  salaryService,
}: {
  currentCompany: Company;
  filteredEmployees: EmployeeProfileType[];
  date;
  salaryRevisions: SalaryRevisionType[];
  selectedParams: SalaryParamType[];
  salaryService: SalaryInterface;
}): JSX.Element {
  const [mode, setMode] = useState<'real' | 'simulate'>('real');
  const { cgSetting } = useContext(LocationContext);

  const report: XlsxDownloadMapper = [
    { columnName: 'employee', dataBinding: { property: 'employee' } },
    {
      columnName: 'employeeId',
      dataBinding: { property: 'employeeId' },
    },
    {
      columnName: 'salaryRevision',
      dataBinding: { property: 'salaryRevision' },
    },
    {
      columnName: 'salaryGenerated',
      dataBinding: { property: 'salaryGenerated' },
    },
    {
      columnName: 'reason',
      dataBinding: { property: 'reason' },
    },
    {
      columnName: 'employeeTakeHome',
      dataBinding: { property: 'employeeTakeHome' },
    },
    {
      columnName: 'employeeGrossEarnings',
      dataBinding: { property: 'employeeGrossEarnings' },
    },
    {
      columnName: 'adjustedPayableDays',
      dataBinding: { property: 'adjustedPayableDays' },
    },
    {
      columnName: 'totalCtc',
      dataBinding: { property: 'totalCtc' },
    },
    {
      columnName: 'ctcAnnual',
      dataBinding: { property: 'ctcAnnual' },
    },
    {
      columnName: 'esic',
      dataBinding: { property: 'esic' },
    },
  ];

  async function downloadReport() {
    const eligibleEmployees: Report[] = [];
    const ineligibleEmployees: EmployeeProfileType[] = [];

    for (const employee of filteredEmployees) {
      try {
        const selectedParam = selectedParams.find(
          (x) => x.name === employee.details.salaryParam?.name
        );

        const selectedSalRev = _.chain(salaryRevisions || [])
          .filter(
            (x) =>
              x.employee.id.toLowerCase() === employee.id.toLowerCase() &&
              x.status === 'published' &&
              moment(x.date).isSameOrBefore(moment(`${date}-01`))
          )
          .sort((a, b) => {
            return parseInt(b.revisionNum) - parseInt(a.revisionNum);
          })
          .first()
          .value();

        if (!selectedParam) {
          throw new Error('No Salary Param Selected');
        }

        if (!selectedSalRev) {
          throw new Error('No Salary Revision Selected');
        }

        const finalSalaryParams = await salaryService
          .calculateSalary({
            employeeId: employee.id,
            month: moment(date).format('YYYY-MM'),
            salaryRevisionId: selectedSalRev.id,
            salaryParamId: selectedParam.id,
            mode: mode,
            companyGroupSetting: cgSetting,
          })
          .then((res) => {
            if (res.salary?.salaryParams) {
              return res.salary?.salaryParams;
            }

            return null;
          });

        if (!finalSalaryParams) {
          throw new Error('Failed to Generate Salary');
        }

        const esicApplicable =
          selectedSalRev?.details?.esicApplicability?.data?.value || '';

        eligibleEmployees.push({
          employee: employee.name,
          employeeId: employee.details.employeeId,
          salaryRevision: selectedSalRev.name,
          salaryGenerated: 'yes',
          employeeTakeHome: formatParam({
            value: finalSalaryParams.earning.employeeTakeHome,
            metric: 'number',
            scale: findParamType(
              'employeeTakeHome',
              selectedParam.details.inner,
              'scale'
            ),
          }),
          // finalSalaryParams.earning.employeeTakeHome as string,
          employeeGrossEarnings: finalSalaryParams.earning
            .grossEarnings as string,
          adjustedPayableDays: finalSalaryParams.other
            .adjustedPayableDays as string,
          ctcAnnual: finalSalaryParams.earning.ctcAnnual,
          totalCtc: finalSalaryParams.earning.totalCtc,
          reason: '',
          esic: esicApplicable,
        });
      } catch (err) {
        eligibleEmployees.push({
          employee: employee.name,
          employeeId: employee.details.employeeId,
          salaryRevision: '',
          employeeTakeHome: '',
          salaryGenerated: 'no',
          employeeGrossEarnings: '',
          adjustedPayableDays: '',
          ctcAnnual: '',
          totalCtc: '',
          esic: '',
          reason: (err as any).message || 'Failed during calculation',
        });
      }
    }
    console.log(eligibleEmployees.length, ineligibleEmployees.length);
    return [
      {
        data: eligibleEmployees,
        mapper: report,
        fileName: `${currentCompany.shortName}-${date}-Salary-Report`,
      },
    ];
  }

  const Report = renderBulkDownload({
    mapperFun: downloadReport,
    downloadIcon: ExcelDownload,
    name: 'Download Report',
  });

  return (
    <div className='w-full'>
      <div className='text-center'>
        Download Report of {filteredEmployees.length} employees
      </div>
      <div className='w-full flex justify-center'>
        <div className='my-auto'>Select Mode: </div>
        <select
          value={mode}
          className='border border-gray-100 p-1 rounded'
          onChange={(e) => setMode(e.target.value as 'real' | 'simulate')}
        >
          <option value='real'>Real</option>
          <option value='simulate'>Simulate</option>
        </select>
      </div>
      <div className='w-full flex justify-center my-2'>
        {/* <LoadingButton behaviourParams={{}} text={'Download'} behaviorFn={download} /> */}
        <Report />
      </div>
    </div>
  );
}

// type SalParamType = {
//   other: any;
//   earning: any;
//   deduction: any;
// };

// async function calculateSalary({
//   selectedSalRev,
//   selectedParam,
//   employee,
//   companyGroupSetting,
//   date,
//   attendanceData,
//   simulate,
//   monthDetails,
//   misconducts,
//   salaryAdvances,
//   premiums,
//   getLoans,
//   getRepaymentSchedules,
// }: {
//   selectedSalRev: SalaryRevisionType | undefined;
//   selectedParam: SalaryParamType;
//   employee: EmployeeProfileType;
//   companyGroupSetting: CompanyGroupSetting;
//   attendanceData: AttendanceType[];
//   date: string;
//   simulate: boolean;
//   misconducts: MisconductType[];
//   monthDetails: {
//     month: number;
//     year: number;
//     monthName: string;
//     totalDays: number;
//     totalOfficialDays: number;
//   };
//   salaryAdvances: SalaryAdvanceType[];
//   premiums: PremiumsType[];
//   getLoans: (filter?: LoanFilter | undefined) => Promise<LoanV2Type[]>;
//   getRepaymentSchedules: (
//     filter?: RepaymentScheduleFilter
//   ) => Promise<RepaymentScheduleType[]>;
// }): Promise<SalParamType> {
//   let finalSalaryParams: SalParamType = {
//     other: {},
//     earning: {},
//     deduction: {},
//   };

//   function setFinalSalaryParams(newFinalSalary: {
//     other: {};
//     earning: {};
//     deduction: {};
//   }) {
//     finalSalaryParams.other = newFinalSalary.other;
//     finalSalaryParams.earning = newFinalSalary.earning;
//     finalSalaryParams.deduction = newFinalSalary.deduction;
//   }

//   if (selectedSalRev?.details) {
//     await appendOuterDependencies({
//       outer: selectedParam.details?.outer,
//       employee,
//       companyGroupSetting,
//       finalSalaryParams,
//       salaryRev: selectedSalRev.details,
//       date,
//       attendanceData,
//       setFinalSalaryParams: setFinalSalaryParams as any,
//       simulate,
//       monthDetails,
//       misconducts,
//       salaryAdvances,
//       premiums,
//       getLoans,
//       getRepaymentSchedules,
//     });

//     await appendFinalSalaryParam(
//       selectedParam.details?.inner,
//       finalSalaryParams
//     );
//     await new Promise<void>((resolve) => {
//       setTimeout(() => {
//         console.log('waiting for data to update');
//         resolve();
//       }, 2000);
//     });
//   }

//   return finalSalaryParams;
// }
