import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { CardActions, renderCardComponent } from '@erp_core/erp-ui-components';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { renderInvProperties } from '.';
import { LocationContext } from '../../../contexts/location';
import { UseAppearances } from '../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../hooks/admin/constants/odour/use-odours';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../hooks/admin/role-admin/use-roles-admin';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRevisions } from '../../../hooks/inventory/revision/use-revisions';
import {
  InvEntityType,
  InvPropertiesProps,
} from '../../../models/types/inventory/inventory-properties';
import { renderAddProperty } from './forms/add-property';
import { renderAuthEditProperty } from './forms/auth-edit-property';
import { renderRuleUpdateProperty } from './forms/rule-update-property';
import { renderSoftEditProperty } from './forms/soft-edit-property';
import { ClassifierDragItem, PropertiesSorter } from './properties-sorter';

export function renderProperties<T extends InvEntityType>({
  useMetrics,
  useGsts,
  useCurrentUserRoles,
  useRevisions,
  useFileTransfer,
  useRoles,
  useUsers,
  useAppearances,
  useColors,
  useOdours,
  useEmployees,
}: {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useCurrentUserRoles: UseCurrentUserRoles;
  useRevisions: UseRevisions;
  useFileTransfer: UseFileTransfer;
  useRoles: UseRoles;
  useUsers: UseUsers;
  useAppearances: UseAppearances;
  useColors: UseColors;
  useOdours: UseOdours;
  useEmployees: UseEmployeeProfiles;
}): (p: InvPropertiesProps<T>) => JSX.Element {
  const Props = renderInvProperties({
    useMetrics,
    useGsts,
    useOdours,
    useAppearances,
    useCurrentUserRoles,
    useRevisions,
    useFileTransfer,
    useColors,
    useEmployees,
  });
  const Card = renderCardComponent();
  const AddProperty = renderAddProperty();

  return function Properties({
    entity,
    setEntity,
    entityService,
    renderOptions,
    type,
    setRevision,
  }: InvPropertiesProps<T>): JSX.Element {
    // const { getAllSync } = useCompanyGroupSettings();
    const { cgSetting } = useContext(LocationContext);
    const [classifiers, setClassifiers] = useState<
      { name: string; description: string }[]
    >([]);
    const [auth] = useState<{ type: string; id: string; name: string }[]>([]);

    useEffect(() => {
      if (cgSetting?.details) {
        setClassifiers([
          ...((cgSetting?.details?.inventory as any)?.classifiers || []),
        ]);
      }
      // eslint-disable-next-line
    }, [cgSetting]);

    const [itemProperties, setItemProperties] = useState<
      Array<ItemPropertyValue>
    >([]);

    const [classifierItems, setClassifierItems] = useState<
      ClassifierDragItem[]
    >([]);

    function getProperties() {
      entityService.getProperties(entity.id).then((c) => {
        setItemProperties(c);
      });
    }

    useEffect(() => {
      let c: ClassifierDragItem[] = [];
      const existingOrder = entity?.details?.propertiesOrder;

      if (existingOrder) {
        c = existingOrder;
      } else {
        itemProperties.forEach((x) => {
          x.classifiers?.forEach((y) => {
            // Find if the classifier exists
            if (c.find((ci) => ci.name === y.name)) {
              const citem = c.find((ci) => ci.name === y.name);
              if (citem) {
                // Find if the item exists within that classifer
                if (citem.items?.find((cii) => cii.id === x.name)) {
                  // In this case we do nothing
                } else {
                  citem?.items.push({
                    id: x.name,
                    name: x.displayName || x.name,
                  });
                }
              }
            } else {
              c.push({
                name: y.name,
                items: [{ id: x.name, name: x.displayName || x.name }],
              });
            }
          });
        });
      }

      setClassifierItems(c);
    }, [itemProperties, entity?.details?.propertiesOrder]);

    useEffect(() => {
      if (entity?.id) {
        getProperties();
      }
      // eslint-disable-next-line
    }, [entity?.id]);

    const buttons: CardActions[] = [];
    if (renderOptions.renderAdd) {
      buttons.push({
        type: 'button',
        button: {
          name: 'Add',
          behaviour: 'modal',
          modal: {
            title: 'Add Property',
            content: ({ onClose }) => {
              return (
                <AddProperty
                  level={1}
                  mode={'add'}
                  avoidSubmitBehavior={false}
                  initialValues={{
                    name: '',
                    description: '',
                    type: '',
                    mandatory: '',
                    classifiers: [],
                    auth: [],
                  }}
                  classifiers={classifiers}
                  auth={auth}
                  saveItemProp={async (x) => {
                    const props = entity.details?.properties || [];
                    props.push(x);
                    await setEntity(({
                      id: entity.id,
                      details: {
                        properties: props,
                      },
                    } as unknown) as T);
                    getProperties();
                    onClose();
                  }}
                />
              );
            },
          },
        },
      });

      buttons.push({
        type: 'button',
        button: {
          style: 'bg-green-600 text-white',
          name: 'Change Order',
          suffix: (
            <ArrowsUpDownIcon className='cursor-pointer text-white inline w-4' />
          ),
          behaviour: 'modal',
          modal: {
            title: 'Change Order',
            size: 'large',
            content: ({ onClose }: { onClose: () => void }) => {
              return (
                <PropertiesSorter
                  classifiers={classifierItems}
                  onSave={async (res) => {
                    console.log(res);
                    await setEntity(({
                      id: entity.id,
                      details: {
                        propertiesOrder: res,
                      },
                    } as unknown) as T);
                    onClose();
                  }}
                />
              );
            },
          },
        },
      });
    }

    const SoftEditProperty = renderSoftEditProperty<T>({
      itemProperties,
      entity,
      setEntity,
      classifiers,
    });

    const AuthEditProperty = renderAuthEditProperty<T>({
      itemProperties,
      entity,
      useUsers,
      useRoles,
      setEntity,
    });

    const RuleUpdateProperty = renderRuleUpdateProperty<T>({
      itemProperties,
      entity,
      setEntity,
      // modal,
      // setModal,
    });

    function EditProperty({
      idx,
      onClose,
    }: {
      idx: number;
      onClose: () => void;
    }) {
      const obj = itemProperties[idx] || {
        name: '',
        description: '',
        type: '',
        mandatory: '',
        classifiers: [],
      };

      const indexInEntity = entity.details.properties.findIndex(
        (x) => x.name === obj.name
      );

      return (
        <AddProperty
          level={1}
          mode={'edit'}
          avoidSubmitBehavior={true}
          initialValues={obj}
          classifiers={classifiers}
          auth={auth}
          saveItemProp={async (x) => {
            entity.details.properties[indexInEntity] = {
              ...obj,
              ...x,
            };
            await setEntity(({
              id: entity.id,
              details: {
                properties: entity.details.properties,
              },
            } as unknown) as T);
            getProperties();
            onClose();
          }}
        />
      );
    }

    return (
      <div>
        <Card
          header={{
            title: '',
            actions: buttons,
          }}
          body={{
            type: 'jsx-component',
            body: (
              <>
                <Props
                  parentValues={itemProperties}
                  classifierItems={classifierItems}
                  renderEditProp={true}
                  EditProperty={EditProperty}
                  SoftEditProperty={SoftEditProperty}
                  AuthEditProperty={AuthEditProperty}
                  RuleUpdateProperty={RuleUpdateProperty}
                  entityId={entity.id}
                  classifier={renderOptions.classifier}
                  renderAuth={renderOptions.renderAuth}
                  properties={itemProperties}
                  renderEditValue={renderOptions.renderEditValue}
                  type={type}
                  setRevision={async (data) => {
                    await setRevision(data);
                  }}
                />
              </>
            ),
          }}
        />
      </div>
    );
  };
}
