/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';

type Props = {
  data: {};
  onSave: any;
};

// eslint-disable-next-line
export function renderAddSalesMultiItemsEnquiryForm({
  useClients,
}: {
  useClients: UseClients;
}): (props: Props) => JSX.Element {
  return function AddAddSalesMoultiItemsEnquiryForm({
    data,
    onSave,
  }: Props): JSX.Element {
    const [clientDetails, setClientDetails] = useState<any>({});

    const Form = renderFormV2({
      onSubmit: async (form) => {
        setClientDetails({
          name: form.name,
          respondBy: form.respondBy,
          expireBy: form.expireBy,
          client: form.client,
          remark: form.remark,
          status: '',
        });
        //await setClientdetails(form);

        console.log('clientDetails', clientDetails, form);
      },
      style: 'w-1/3',
      button: {
        text: 'Next',
      },
      mapTToU: (f) => f,
      initialFormState: {
        name: '',
        respondBy: '',
        expireBy: '',
        client: '',
        remark: '',
        status: '',
      },
      fieldsData: [
        {
          property: 'client',
          label: 'Client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useClients,
            onSearchValueSelect: (v) => {
              // console.log(v);
            },
          },
          required: true,
        },
        {
          property: 'respondBy',
          label: 'Respond By',
          type: 'date',
          required: true,
        },
        {
          property: 'expireBy',
          label: 'Expired By',
          type: 'date',
          required: true,
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
          required: true,
        },
      ],
    });
    if (!clientDetails.client || clientDetails?.client?.id === '') {
      return <Form />;
    }
    return <div>Form2 {clientDetails?.client?.name}</div>;
  };
}
