import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';
import { LeadEnquiryType } from '../../../../models/types/sales/lead-enquiry';

type Props = {
  onSave: any;
  onClose: any;
};

// eslint-disable-next-line
export function renderAddLeadEnquiryForm({
  useItems,
  useClients,
}: {
  useItems: UseItems;
  useClients: UseClients;
}): (props: Props) => JSX.Element {
  return function AddLeadEnquiryForm({
    onSave,
    onClose,
  }: {
    onSave: any;
    onClose: () => any;
  }): JSX.Element {
    const formProps: FormProps<Partial<LeadEnquiryType>> = {
      fieldsData: [
        {
          property: 'enquiryNo',
          type: 'input',
          required: true,
          label: 'Enquiry-No',
        },
        {
          property: 'enqDt',
          type: 'date',
          required: true,
          label: 'Enquiry Date',
        },
        {
          property: 'modeOfEnquiry',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'verbal', value: 'verbal' },
            { text: 'phone-call', value: 'phone-call' },
            { text: 'message', value: 'message' },
            { text: 'whatsapp', value: 'whatsapp' },
            { text: 'notes', value: 'notes' },
          ],
          required: true,
          label: 'Mode Of Enquiry',
        },
        {
          property: 'typeOfEnq',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'new-buisness', value: 'new-buisness' },
            { text: 'existing-buisness', value: 'existing-buisness' },
          ],
          required: true,
          label: 'Type Of Enquiry',
        },
        {
          property: 'customer',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useClients,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
          required: true,
          label: 'Customer/Client',
        },
        {
          property: 'contact',
          type: 'input',
          required: true,
          label: 'Contact',
        },
        {
          property: 'deliveryLocation',
          type: 'input',
          required: true,
          label: 'Delivery Location',
        },
        {
          property: 'desc',
          type: 'text-area',
          required: true,
          label: 'Description',
        },
        {
          property: 'product',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
          required: true,
          label: 'Product',
        },
        {
          property: 'endUse',
          type: 'input',
          required: true,
          label: 'End Use',
        },
        {
          property: 'totQty',
          type: 'number',
          required: true,
          label: 'Total Quantity',
        },
        {
          property: 'uom',
          type: 'input',
          required: true,
          label: 'UOM',
        },
        {
          property: 'assignTo',
          type: 'input',
          required: true,
          label: 'Assign To',
        },
        {
          property: 'nextFollowUpDt',
          type: 'date',
          required: true,
          label: 'Next follow-up-date',
        },
        {
          property: 'stage',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'lead-generated', value: 'lead-generated' },
            { text: 'contacted', value: 'contacted' },
            { text: 'meeting-scheduled', value: 'meeting-scheduled' },
            { text: 'sample-sent', value: 'sample-sent' },
            { text: 'qualified-to-buy', value: 'qualified-to-buy' },
            { text: 'disqulified', value: 'disqulified' },
            { text: 'enquiry', value: 'enquiry' },
            { text: 'offer', value: 'offer' },
          ],
          required: true,
          label: 'stage',
        },
        {
          property: 'remark',
          type: 'input',
          required: true,
          label: 'remarks',
        },
      ],
      initialFormState: {
        name: '',
        enquiryNo: '',
        modeOfEnquiry: '',
        deliveryLocation: '',
        customer: '',
        product: '',
        totQty: '',
        uom: '',
        nextFollowUpDt: '',
        remark: '',
        enqDt: '',
        typeOfEnq: '',
        contact: '',
        desc: '',
        endUse: '',
        assignTo: '',
        stage: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (data) => {
        onSave(data);
        onClose();
      },
    };

    const Form = renderFormV2<Partial<LeadEnquiryType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<LeadEnquiryType>>
    ): Partial<LeadEnquiryType> {
      return (b as unknown) as LeadEnquiryType;
    }
  };
}
