import {
  GroupLeavesType,
  LeaveAppType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export function EditLeave({
  leaves,
  leave,
}: {
  leaves: LeaveAppType[];
  leave: GroupLeavesType;
}) {
  const [sortedLeaves, setSortedLeaves] = useState<
    {
      date: string;
      first: LeaveAppType | null;
      second: LeaveAppType | null;
    }[]
  >([]);

  const [errors, setErrors] = useState<string>('');

  useEffect(() => {
    const filtered = _.sortBy(
      leaves.filter(
        (x) =>
          x.employee.id === leave.leave.employee?.id &&
          x.dateStart === leave.leave.dateStart &&
          x.dateEnd === leave.leave.dateEnd
      ),
      'name'
    );

    const sLeaves: {
      date: string;
      first: LeaveAppType | null;
      second: LeaveAppType | null;
    }[] = [];

    let summary = '';

    filtered.forEach((x) => {
      const date = x.name.replace('-first', '').replace('-second', '');
      const isFirst = x.name.includes('-first');
      const isSecond = x.name.includes('-second');
      const sLeave = sLeaves.find((y) => y.date === date);
      if (sLeave) {
        if (isFirst) {
          if (sLeave.first === null) {
            sLeave.first = x;
          } else {
            summary += `${date} first leave already exists. `;
          }
        }
        if (isSecond) {
          if (sLeave.second === null) {
            sLeave.second = x;
          } else {
            summary += `${date} second leave already exists. `;
          }
        }
      } else {
        sLeaves.push({
          date: date,
          first: isFirst ? x : null,
          second: isSecond ? x : null,
        });
      }
    });

    setSortedLeaves(sLeaves);
    setErrors(summary);
  }, [leaves, leave]);

  const Card = renderCardComponent();
  return (
    <div>
      <Card
        header={{
          title: `Edit Leave for ${sortedLeaves[0]?.first?.employee.name}`,
        }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {errors ? <div className='text-red-500'>{errors}</div> : null}
              {sortedLeaves.map((x, idx) => {
                return (
                  <div key={idx}>
                    {x.date} - First- {x.first?.status} - Second{' '}
                    {x.second?.status} -{x.first?.dateStart} -{' '}
                    {x.first?.dateEnd}
                  </div>
                );
              })}
            </>
          ),
        }}
      />
    </div>
  );
}
